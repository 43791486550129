$info-color: $info !default;
$ball-size: 15px !default;
$margin: 2px !default;
$line-height: 35px !default;
$line-width: 4px !default;

@mixin global-bg() {
	background-color: $info-color;
}

@mixin global-animation() {
	animation-fill-mode: both;
}

@mixin balls() {
	@include global-bg();

	width: $ball-size;
	height: $ball-size;
	border-radius: 100%;
	margin: $margin;
}

@mixin lines() {
	@include global-bg();

	width: $line-width;
	height: $line-height;
	border-radius: 2px;
	margin: $margin;
}

/**
 * Dots
 */
@import "../../../node_modules/loaders.css/src/animations/ball-pulse";
@import "../../../node_modules/loaders.css/src/animations/ball-pulse-sync";
@import "../../../node_modules/loaders.css/src/animations/ball-scale";
@import "../../../node_modules/loaders.css/src/animations/ball-rotate";
@import "../../../node_modules/loaders.css/src/animations/ball-clip-rotate";
@import "../../../node_modules/loaders.css/src/animations/ball-clip-rotate-pulse";
@import "../../../node_modules/loaders.css/src/animations/ball-clip-rotate-multiple";
@import "../../../node_modules/loaders.css/src/animations/ball-scale-ripple";
@import "../../../node_modules/loaders.css/src/animations/ball-scale-ripple-multiple";
@import "../../../node_modules/loaders.css/src/animations/ball-beat";
@import "../../../node_modules/loaders.css/src/animations/ball-scale-multiple";
@import "../../../node_modules/loaders.css/src/animations/ball-triangle-trace";
@import "../../../node_modules/loaders.css/src/animations/ball-pulse-rise";
@import "../../../node_modules/loaders.css/src/animations/ball-grid-beat";
@import "../../../node_modules/loaders.css/src/animations/ball-grid-pulse";
@import "../../../node_modules/loaders.css/src/animations/ball-spin-fade-loader";
@import "../../../node_modules/loaders.css/src/animations/ball-spin-loader";
@import "../../../node_modules/loaders.css/src/animations/ball-zig-zag";
@import "../../../node_modules/loaders.css/src/animations/ball-zig-zag-deflect";

/**
 * Lines
 */
@import "../../../node_modules/loaders.css/src/animations/line-scale";
@import "../../../node_modules/loaders.css/src/animations/line-scale-random";
@import "../../../node_modules/loaders.css/src/animations/line-scale-pulse-out";
@import "../../../node_modules/loaders.css/src/animations/line-scale-pulse-out-rapid";
@import "../../../node_modules/loaders.css/src/animations/line-spin-fade-loader";

/**
 * Misc
 */
@import "../../../node_modules/loaders.css/src/animations/triangle-skew-spin";
@import "../../../node_modules/loaders.css/src/animations/square-spin";
@import "../../../node_modules/loaders.css/src/animations/pacman";
@import "../../../node_modules/loaders.css/src/animations/cube-transition";
@import "../../../node_modules/loaders.css/src/animations/semi-circle-spin";
