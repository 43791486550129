// Variables

$spacer-lg: 3rem;
$spacer-sm: 1.5rem;

$layout-spacer-lg: 3rem;
$layout-spacer-x: 0.5rem;
$layout-spacer-x2: -0.5rem;

$app-sidebar-width: 220px;
$app-sidebar-width-collapsed: 80px;
$logo-height: 60px;
$logo-width: 145px;

$app-header-height: 50px;
