.map-continer {
	width: 100%;
	height: 100vh;
}

.geolocation-button {
	font-size: 25px;
	z-index: 100;
	position: absolute;
	bottom: 200px;
	right: 10px;
	border: none;
}
