@import "_mixins";

.rbt {
	outline: none;

	&-menu {
		margin-bottom: 2px;

		& > li a {
			overflow: hidden;
			text-overflow: ellipsis;

			&:focus {
				outline: none;
			}
		}

		&-pagination-option {
			text-align: center;
		}
	}

	// Hide IE's native "clear" button
	& .rbt-input-main::-ms-clear {
		display: none;
	}

	&-input-multi {
		cursor: text;
		overflow: hidden;
		position: relative;

		// BS4 uses the :disabled pseudo-class, which doesn't work with non-inputs.
		&.form-control[disabled] {
			background-color: #e9ecef;
			opacity: 1;
		}

		input {
			@include placeholder(#6c757d);
		}

		.rbt-input-wrapper {
			margin-top: 2px;
			overflow: hidden;
		}

		.rbt-input-main {
			height: auto;
		}

		.rbt-input-hint-container {
			display: inline-block;
		}

		&.input,
		&.form-control {
			&-lg {
				.rbt-input-main {
					height: auto;
				}
			}

			&-sm {
				.rbt-input-main {
					height: auto;
				}
			}
		}
	}

	&-close {
		z-index: 1;

		&-lg {
			font-size: 24px;
		}
	}

	&-token {
		background-color: $dropdown-link-hover-bg;
		border: 0;
		@include border-radius($border-radius);
		color: $info;
		display: inline-block;
		line-height: 1em;
		margin: 0 3px 3px 0;
		padding: 4px 7px;
		position: relative;

		&-disabled {
			background-color: $gray-100;
			color: $gray-500;
			pointer-events: none;
		}

		&-removeable {
			cursor: pointer;
			padding-right: 21px;
		}

		&-active {
			background-color: $info;
			color: $white;
			outline: none;
			text-decoration: none;
		}

		& &-remove-button {
			bottom: 0;
			color: $gray-700;
			font-size: inherit;
			font-weight: normal;
			opacity: 1;
			outline: none;
			padding: 3px 7px;
			position: absolute;
			right: 0;
			text-shadow: none;
			top: -1px;
		}
	}

	&-loader {
		@include animation(loader-animation 600ms infinite linear);
		border: 1px solid $dropdown-link-hover-bg;
		border-radius: 50%;
		border-top-color: darken($dropdown-link-hover-bg, 10%);
		display: block;
		height: 16px;
		width: 16px;

		&-lg {
			height: 20px;
			width: 20px;
		}
	}

	&-aux {
		@include display-flex();
		align-items: center;
		bottom: 0;
		justify-content: center;
		pointer-events: none; /* Don't block clicks on the input */
		position: absolute;
		right: 0;
		top: 0;
		width: 34px;

		&-lg {
			width: 46px;
		}

		& .rbt-close {
			margin-top: -1px;
			pointer-events: auto; /* Override pointer-events: none; above */
		}
	}

	.has-aux &-input {
		padding-right: 34px;
	}

	&-highlight-text {
		background-color: inherit;
		color: inherit;
		font-weight: bold;
		padding: 0;
	}
}

/* Input Groups */
.input-group > .rbt {
	@include flex(1);

	& .rbt-input-hint-container {
		@include display-flex();
	}

	& .rbt-input-hint {
		// Form-controls within input-groups have a higher z-index.
		z-index: 5;
	}

	& .rbt-aux {
		// Form-controls within input-groups have a higher z-index.
		z-index: 5;
	}

	&:not(:first-child) .form-control {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	&:not(:last-child) .form-control {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
}

@keyframes loader-animation {
	to {
		transform: rotate(1turn);
	}
}

@-webkit-keyframes loader-animation {
	to {
		-webkit-transform: rotate(1turn);
	}
}

.input-group > .rbt {
	& .rbt-input-hint-container {
		@include flex(1);
		height: 100%;
	}
}
