// GENERIC

$enable-caret: true;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-grid-classes: true;
$enable-print-styles: false;

$body-bg: #fff;
$header-color: #343a40;
$app_bg_color: #f1f4f6;

// Colors

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$grays: ();

$grays: map-merge(
	(
		"100": $gray-100,
		"200": $gray-200,
		"300": $gray-300,
		"400": $gray-400,
		"500": $gray-500,
		"600": $gray-600,
		"700": $gray-700,
		"800": $gray-800,
		"900": $gray-900,
	),
	$grays
);

//--#{$color}
$brand: #545cd8;
$blue: #86c6fb;
$indigo: #444054;
$purple: #83588a;
$pink: #f65ca2;
$red: #d92550;
$orange: #fd7e14;
$yellow: #f7b924;
$green: #3ac47d;
$teal: #16e0bd;
$cyan: #30b1ff;
$blue-light: #eeeeee;
$face-blue: #1877f2;

$info: $brand;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $blue-light;
$dark: $gray-800;
$focus: $indigo;
$alt: $purple;

$theme-colors: ();

$theme-colors: map-merge(
	(
		"primary": $info,
		"secondary": $secondary,
		"success": $success,
		"info": $info,
		"warning": $warning,
		"danger": $danger,
		"light": $light,
		"dark": $dark,
		"focus": $focus,
		"alternate": $alt,
		"face-blue": $face-blue,
	),
	$theme-colors
);

// BOOTSTRAP OVERWRITES

$headings-font-weight: 400;

$font-size-base: 0.88rem;
$font-size-lg: ($font-size-base * 1.25);
$font-size-sm: ($font-size-base * 1.1);
$font-size-xs: ($font-size-base / 1.1);

$btn-transition: color 0.15s, background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
$btn-focus-width: 0;
$btn-focus-box-shadow: none;

$pagination-focus-box-shadow: none;

$body-color: $gray-700;

$card-cap-bg: $white;
$card-border-color: rgba(darken($dark, 25%), 0.125);

$dropdown-min-width: 15rem;
$dropdown-link-hover-bg: #e0f3ff;
$dropdown-item-padding-y: 0.4rem;
$dropdown-padding-y: 0.65rem;

$box-shadow-default: 0 0.46875rem 2.1875rem rgba(darken($info, 50%), 0.03), 0 0.9375rem 1.40625rem rgba(darken($info, 50%), 0.03),
	0 0.25rem 0.53125rem rgba(darken($info, 50%), 0.05), 0 0.125rem 0.1875rem rgba(darken($info, 50%), 0.03);

$box-shadow-default-right: 0.46875rem 0 2.1875rem rgba(darken($info, 50%), 0.03),
	0.9375rem 0 1.40625rem rgba(darken($info, 50%), 0.03), 0.25rem 0 0.53125rem rgba(darken($info, 50%), 0.05),
	0.125rem 0 0.1875rem rgba(darken($info, 50%), 0.03);

$box-shadow-default-inverse: 0 -0.46875rem 2.1875rem rgba(darken($info, 50%), 0.03),
	0 -0.9375rem 1.40625rem rgba(darken($info, 50%), 0.03), 0 -0.25rem 0.53125rem rgba(darken($info, 50%), 0.05),
	0 -0.125rem 0.1875rem rgba(darken($info, 50%), 0.03);

$box-shadow-lg-default: 0 0.76875rem 2.4875rem rgba($gray-800, 0.3), 0 1.3375rem 1.70625rem rgba($gray-800, 0.3),
	0 0.55rem 0.53125rem rgba(darken($gray-800, 50%), 0.05), 0 0.225rem 0.4375rem rgba($gray-800, 0.3);

$form-check-input-margin-y: 0.3rem;

$input-group-addon-bg: lighten($dropdown-link-hover-bg, 3%);

// Tables

$table-accent-bg: rgba($black, 0.03);
$table-hover-bg: $dropdown-link-hover-bg;
$table-active-bg: $table-hover-bg;
$table-border-color: $gray-200;
$table-head-bg: $gray-100;
$table-head-color: $gray-600;
$table-cell-padding: 0.55rem;
$table-cell-padding-sm: 0.3rem;

// Popovers

$popover-max-width: 320px;
$popover-box-shadow: $box-shadow-default;
$popover-border-color: $card-border-color;
$popover-header-bg: $white;

// Color Modifiers

$link-color: theme-color("info");
$component-active-bg: theme-color("info");
$custom-control-indicator-checked-disabled-bg: rgba(theme-color("info"), 0.5);
$form-feedback-valid-color: theme-color("success");
$form-feedback-invalid-color: theme-color("danger");
$progress-bar-bg: theme-color("info");
$input-btn-focus-color: rgba($component-active-bg, 0.25);
$input-focus-border-color: lighten($component-active-bg, 25%);
$custom-control-indicator-checked-bg: $component-active-bg;
$custom-control-indicator-active-bg: lighten($component-active-bg, 35%);
$custom-checkbox-indicator-indeterminate-bg: $component-active-bg;
$custom-range-thumb-bg: $component-active-bg;
$custom-range-thumb-active-bg: lighten($component-active-bg, 35%);
$dropdown-link-active-bg: $component-active-bg;
$nav-pills-link-active-bg: $component-active-bg;
$pagination-active-bg: $component-active-bg;
$list-group-active-bg: $component-active-bg;

$list-group-border-color: rgba($info, 0.08) !default;

// Mixins

@mixin box-shadow-architect($shadow) {
	box-shadow: 0 0.66875rem 2.3875rem rgba($shadow, 0.03), 0 1.1375rem 1.60625rem rgba($shadow, 0.03),
		0 0.45rem 0.73125rem rgba($shadow, 0.05), 0 0.325rem 0.3875rem rgba($shadow, 0.03);
}

// Border

@each $color, $value in $theme-colors {
	.border-#{$color} {
		border-color: $value;
	}
}

// List Group

@each $color, $value in $theme-colors {
	@include list-group-item-variant($color, theme-color-level($color, -9), theme-color-level($color, 6));
}

// Background

@each $color, $value in $theme-colors {
	@include bg-variant(".bg-#{$color}", $value);
}

@if $enable-gradients {
	@each $color, $value in $theme-colors {
		@include bg-gradient-variant(".bg-gradient-#{$color}", $value);
	}
}
